import React,{ useState, useEffect } from 'react'
// import ReactPageScroller from 'react-page-scroller';
import Header from './components/NavBar';
import {CaretLeft, CaretRight} from '@phosphor-icons/react';
import {AlertContent,  AlertC} from '../../components/Alert';
import {ServerUserList} from '../../requests/Server.request';
import Auth from '../../requests/Auth.request';
import {CircleNotch } from '@phosphor-icons/react';
import {ConvertDate} from '../../scripts/date';
import Footer from '../../components/footer';


function Dashboard(){
    const [ serverList, setServerList ] = useState([]);
    const [ serverLoading, setServerLoading ] = useState([]);

    useEffect(() => {
        Auth.serverList().then(e => {
            setServerList(e.data);
            setServerLoading(new Array(e.data.length).fill(false));

        }).catch(error => {
            console.log(error);
        })

    }, [])
    
    const chooseServer = (svr, i) => { 
        if(serverLoading[i] == true) return;

        if(svr.serv_status == 1 || svr.serv_status == 2 && JSON.parse(localStorage.getItem('user')).user.rank == 3){
            
            Auth.firstLogin(svr.serv_token)
            .then((response) => {   
                // Update the serverLists state with the data from the response
                const newServerLoading = [...serverLoading];
                newServerLoading[i] = true;
                setServerLoading(newServerLoading);
                window.location.href = process.env.REACT_APP_PROTOCOL + svr.serv_token + ".ethernial.it/?pg=Index&act=StartSession&token="+response.data.res.content.token;
            })
            .catch(err => {
                // Handle errors if any
                console.log(err);
                const newServerLoading = [...serverLoading];
                newServerLoading[i] = false;
                setServerLoading(newServerLoading);
                // You may want to display an error message to the user
            });
        }else{
            AlertC({
                variant: "danger",
                children: "Il server è chiuso o non sei un Admin",
                timeOut: 4000
            });
        }


    }

    return (
        <div className='bg-white'>
            <Header />

            <div className='container mx-auto px-4 my-10'>
                <h1 className='text-4xl font-extrabold text-center'>Lista dei server</h1>
                <p className='text-gray-700 text-center text-lg'>In questa pagina hai la lista di tutti i server giocati con le relative statistiche di gioco più i server attivi e già registrati.</p>

                <div className='md:w-[550px] w-auto mx-auto'>
                    {
                        serverList.map((item, i) => {

                            return <div onClick={() => chooseServer(item, i)} key={i} className='border-b border-gray-300 my-4 pb-4 relative'>
                                <div className="border-l-4 hover:border-[#eaca9f] pl-3 cursor-pointer">
                                    <h3 className='text-lg font-medium'>{item.serv_name}</h3>
                                    <p className=''>Stato: {item.serv_status == 1 ? <span className='text-green-400 font-medium'>Attivo</span> : item.serv_status == 2 ? <span className='text-green-400 font-medium'>Attivo solo agli Admin</span> : <span className='text-red-400 font-medium'>Chiuso</span>}</p>
                                    {
                                        item.serv_date_start != null ? 
                                            <p className=''>Aperto dal: <span className='text-gray-600 font-medium'>{ConvertDate(item.serv_date_start)}</span></p>
                                            : 
                                            <></>
                                    }
                                    {
                                        item.serv_date_end != null ? 
                                            <p className=''>Chiude il <span className='text-gray-600 font-medium'>{ConvertDate(item.serv_date_end)}</span></p>
                                            : 
                                            <></>
                                    }
                                    <CircleNotch className={["animate-spin h-5 w-5 mr-3 absolute right-5 top-5", serverLoading[i] ? "block" : "hidden"].join(" ")}  />
                                </div>
                            </div>
                        })
                    }
                    {/* <div className='border-b border-gray-300 my-4 pb-4'>
                        <div className="border-l-4 hover:border-[#eaca9f] pl-3 cursor-pointer">
                            <h3 className='text-lg font-medium'># Server1</h3>
                            <p className=''>Stato: <span className='text-green-400 font-medium'>Attivo</span></p>
                            <p className=''>Aperto dal: <span className='text-gray-600 font-medium'>09 Settembre 2024</span></p>
                            <p className=''>Chiude il <span className='text-orange-600 font-medium'>10 Settembre 2024</span></p>
                        </div>
                    </div>
                    <div className='border-b border-gray-300 my-4 pb-4'>
                        <div className="border-l-4 hover:border-[#eaca9f] pl-3 cursor-pointer">
                            <h3 className='text-lg font-medium'># Server2</h3>
                            <p className=''>Stato: <span className='text-green-400 font-medium'>Attivo</span></p>
                            <p className=''>Aperto dal: <span className='text-gray-600 font-medium'>09 Settembre 2024</span></p>
                        </div>
                    </div>
                    <div className='border-b border-gray-300 my-4 pb-4'>
                        <div className="border-l-4 hover:border-[#eaca9f] pl-3">
                            <h3 className='text-lg font-medium'># Server3</h3>
                            <p className=''>Stato: <span className='text-green-400 font-medium'>Attivo</span></p>
                            <p className=''>Aperto dal: <span className='text-gray-600 font-medium'>09 Settembre 2024</span></p>
                            <p className=''>Chiuso il <span className='text-red-600 font-medium'>09 Settembre 2024</span></p>
                            <p className=''>Statistiche disponibili: <a href="#" className='text-blue-600 font-medium underline'>Qui</a></p>
                        </div>
                    </div> */}
                </div>
                <div className="text-center flex justify-center items-center mt-10">
                    <a href="" className='text-md hover:underline'><CaretLeft /></a>
                    <a href="" className='text-md hover:underline mx-3'>1</a>
                    <a href="" className='text-md hover:underline '><CaretRight /></a>
                </div>
            </div>

            <AlertContent direction="bottom-right"></AlertContent>
            <Footer />
        </div>
    );
}

export default Dashboard;
'use client'

import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from '../../../../assets/img/logo.png';
import Bg from '../../../../assets/img/LandingBk.png';
import Modal from '../ModalAuth';
import {isAuthenticated} from '../../../../components/RequireAuth';
import { Link } from 'react-router-dom';
import './style.css';


export default function Example() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [userAuthenticateBool, setUserAuthenticate] = useState(false);
    
    const navigation = [
      { name: 'Home', href: '/', active: true },
      { name: 'Forum', href: 'https://forum.ethernial.it' },
      { name: 'Help', href: 'https://wiki.ethernial.it/' },
      { name: 'News', href: 'https://forum.ethernial.it/viewforum.php?f=2' },
    ]


    const showModal = () => {
        setMobileMenuOpen(false);
        setShowAuthModal(true);
    }
    const closeAuthModal = () => {
        setShowAuthModal(false);
    }

    const userAuthenticate = (e) => {
        setUserAuthenticate(e);
    }
    

  return (
    <div className="bg-white cover-bg bg-center  md:h-screen h-full ">
        <header className="md:absolute relative inset-x-0 top-0 z-50">
            <nav aria-label="Global" className="relative md:fixed top-0 left-0 w-full flex items-center justify-between p-6 lg:px-8 after:absolute after:left-40 after:bottom-1 after:mx-6 after:w-[86%] after:md:block after:hidden after:border-b after:border-[#eaca9f]">
                <div className="flex lg:flex-1 mx-auto">
                    <Link to="#" className="-m-1.5 p-1.5 md:initial absolute top-0 md:left-0 left-1/2 md:translate-x-0 -translate-x-20">
                        <span className="sr-only">Ethernial</span>
                        <img
                            alt=""
                            src={Logo}
                            className="h-28 w-auto relative z-10 md:top-10 top-0 md:left-8 left-0"
                        />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                    type="button"
                    onClick={() => setMobileMenuOpen(true)}
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                    >
                    <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12 ">
                    {navigation.map((item) => (
                        <Link key={item.name} to={item.href} className={["text-lg relative font-semibold leading-6  after:absolute after:top-12 after:w-2 after:h-2 after:inset-2/4 after:rounded-full", item.active ? "after:bg-[#eaca9f] text-[#eaca9f]" : "text-gray-400 hover:text-gray-200"].join(" ")}>
                            {item.name}
                        </Link>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    { isAuthenticated() || userAuthenticateBool ? 
                        <Link to="/Dashboard" className="text-lg relative font-semibold leading-6 h-10 items-center flex after:absolute after:top-12 after:w-2 after:h-2 after:inset-2/4 after:rounded-full text-gray-400 hover:text-gray-200">
                            Entra
                        </Link>
                    :
                        <Link onClick={showModal} className="bg-log-button bg-no-repeat bg-cover h-10 active:bg-[center_top_-84px] hover:bg-[center_top_-42px] px-3 py-1 rounded-md text-lg font-semibold leading-6 text-transparent ">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </Link>
                    }
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
            <div className="fixed inset-0 z-50" />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <Link to="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Ethernial</span>
                        <img
                        alt=""
                        src={Logo}
                        className="h-8 w-auto"
                        />
                    </Link>
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-m-2.5 rounded-md p-2.5 text-gray-900"
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/30">
                    <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                        {item.name}
                        </a>
                    ))}
                    </div>
                    <div className="py-6">

                        { isAuthenticated() || userAuthenticateBool ? 
                            <Link to="/Dashboard" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                Entra
                            </Link>
                        :
                            <Link onClick={showModal} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ">
                                Log in <span aria-hidden="true">&rarr;</span>
                            </Link>
                        }
                    </div>
                </div>
                </div>
            </DialogPanel>
            </Dialog>
        </header>

        <div className="relative isolate px-6 py-auto lg:px-8 h-full flex items-center mx-auto md:w-[1320px] w-full">

            <div className=" ">
                <div className="text-left">
                    <h1 className="md:text-[5rem] text-[4rem] md:mt-0 mt-24 font-bold tracking-tight text-gray-200 sm:text-6xl uppercase">
                        Conquista <br />
                        altre<br />
                        <div className='text-[5rem] md:text-[7rem] text-teal-400'>regioni</div>
                    </h1>
                
                </div>
            </div>
        </div>
        <div className='w-full md:absolute relative bottom-0 left-0 py-8 px-8 md:px-36 select-none flex justify-between overflow-x-auto md:overflow-auto'>
            <div className=' '>
                <div className='flex items-center'>
                    <div className='relative bg-white rounded-lg shadow-lg overflow-hidden w-[250px] h-40 row-start-1 row-end-2 transition-[filter] duration-500'>
                        <div className='absolute top-0 left-0 w-full h-full bg-play-image bg-no-repeat bg-center cursor-pointer z-10 '></div>
                        <img className="shadow-md  absolute inset-0 w-full h-full object-cover" loading="lazy" src="https://th.bing.com/th/id/OIG3.YLCRhr8TEUU0.v5wWAaX?pid=ImgGn" alt="" />
                    </div>
                    <div className='w-[300px] md:block hidden'>
                        <p className='ml-3 text-md font-semibold text-white uppercase text-shadow-md shadow-black'>Benvenuto su Ethernial</p>
                        <p className='ml-3 text-sm  text-gray-300 text-shadow-md shadow-black'>Metti in atto la tua strategia nel combattimento...</p>
                    </div>
                </div>
            </div>
            {/* Pagination scroll */}
            <div className='w-7 grid gap-0 justify-items-start ' >
                <a href="#" className='w-0 h-5 bg-white text-black overflow-hidden border-l-2 border-white  justify-self-start self-center shadow-sm shadow-black'>
                    01
                </a>
                <a href="#" className='w-full h-5 bg-white text-black text-center text-sm rounded-md justify-self-start self-center  shadow-sm shadow-black'>
                    02
                </a>
                <a href="#" className='w-0 h-5 bg-white text-black overflow-hidden border-l-2 border-white justify-self-start self-center  shadow-sm shadow-black'>
                    03
                </a>
                <a href="#" className='w-0 h-5 bg-white text-black overflow-hidden border-l-2 border-white justify-self-start self-center  shadow-sm shadow-black'>
                    04
                </a>
            </div>
        </div>
        {/* <Modal show={false} onHide={() => this.setState({ modalShow: false })} cardOpn={this.state.cardOpn} /> */}
        <Modal show={showAuthModal} onHide={closeAuthModal} authenticated={userAuthenticate} />
    </div>
  )
}

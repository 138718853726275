import React from 'react'
import {FacebookLogo, InstagramLogo, XLogo, GithubLogo, YoutubeLogo} from '@phosphor-icons/react';

export default function Footer(){

    return <footer className="bg-white">
            <div className="mx-auto  max-w-[80rem] px-6 py-12 flex items-center justify-between md:flex-row flex-col md:px-8 gap-4">
                <div className="flex justify-center order-2">
                    <a href="https://www.facebook.com/EthernialWar" className="text-gray-500 mr-3">
                        <span className="hidden">Facebook</span>
                        <FacebookLogo weight="duotone" size="24" />
                    </a>
                    <a href="#" className="text-gray-500 mr-3">
                        <span className="hidden">Instagram</span>
                        <InstagramLogo weight="duotone" size="24" />
                    </a>
                    <a href="#" className="text-gray-500 mr-3">
                        <span className="hidden">X</span>
                        <XLogo weight="duotone" size="24" />
                    </a>
                    <a href="#" className="text-gray-500 mr-3">
                        <span className="hidden">GitHub</span>
                        <GithubLogo weight="duotone" size="24" />
                    </a>
                    <a href="#" className="text-gray-500">
                        <span className="hidden">YouTube</span>
                        <YoutubeLogo weight="duotone" size="24" />
                    </a>
                </div>
                <div className="md:order-1 mt-0">
                    <p className="text-gray-500 leading-5 text-xs text-center font-mono">© 2024 ethernial.it . All rights reserved. v{process.env.REACT_APP_VERSION_DESK}</p>
                </div>
            </div>
        </footer>
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import {RequireAuth} from './components/RequireAuth';
import ServerList from './pages/Dashboard/ServerList';
import Settings from './pages/Dashboard/Settings';
import Alerts from './pages/Dashboard/Alerts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<RequireAuth Component={Landing} page={true} restricted={true} />} />
            <Route exact path="/Dashboard" element={<RequireAuth Component={Dashboard} page={true} restricted={false} />} />
            <Route exact path="/ServerList" element={<RequireAuth Component={ServerList} page={true} restricted={false} />} />
            <Route exact path="/Settings" element={<RequireAuth Component={Settings} page={true} restricted={false} />} />
            <Route exact path="/Alerts" element={<RequireAuth Component={Alerts} page={true} restricted={false} />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from 'axios';

function register(userName, email, password, repeatPass) {
    return axios.post(process.env.REACT_APP_ENDPOINT_API + '/api/v1/register', {
        "name": userName,
        "email": email,
        "password": password,
        "password_confirmation": repeatPass
    });
}

function restorePassword( email) {
    return axios.post(process.env.REACT_APP_ENDPOINT_API + '/api/v1/restore', {
        "email": email
    });
}

function login(userName, password) {
    return axios.post(process.env.REACT_APP_ENDPOINT_API + '/api/v1/login', {
        "username": userName,
        "password": password
    });
}

function firstLogin(token) {

    const userLog = JSON.parse(localStorage.getItem('user')).token;

    return axios.post(process.env.REACT_APP_ENDPOINT_API + '/api/v1/user/server', {
        "svr_id": token
    }, {
        headers: {
            'Authorization': `Bearer ${userLog}`
        }
    });
    
    // return axios.put('http://localhost:5001/api/User/1', {}, {
    //     headers: {
    //         "Authorization": `Bearer ${token}` //JSON.parse(localStorage.getItem('user')).auth_token
    //     }
    // });
}

function serverList() {
    return axios.get(process.env.REACT_APP_ENDPOINT_API + '/api/v1/server');
}

const Auth = {
    register,
    login,
    firstLogin,
    serverList,
    restorePassword
}

export default Auth;

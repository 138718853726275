import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import classes from '../../../../assets/css/style.module.css';
import './Modal.css';
import Auth from '../../../../requests/Auth.request'
import {AlertContent, AlertC} from '../../../../components/Alert';



class ModalRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardOpn: 1,
            serverSelected: 0,
            modalClose: true,
            modal: [],
            registration: {
                username: '',
                password: '',
                repPassword: '',
                email: ''
            },
            login: {
                username: '',
                password: ''
            },
            missingPass: {
                email: null
            },
            serverLists: [],
            isLoggedIn: false
        }
    }

    componentDidMount() {
        // Call loadServerList function when the component mounts
        this.loadServerList();
    }

    loadServerList = () => {
        // Call the serverList function from Auth
        Auth.serverList()
            .then((response) => {   
                // Update the serverLists state with the data from the response
                this.setState({
                    serverLists: response.data
                });
            })
            .catch(err => {
                // Handle errors if any
                
                if(err.code == "ERR_NETWORK"){
                    AlertC({
                        variant: "danger",
                        children: "Network connection",
                        timeOut: 4000
                    });
                }
                // You may want to display an error message to the user
            });
    }

    handleClose = () => {
        this.props.onHide(false);
        this.setState({
            cardOpn: 1
        });
    }

    isValidEmail =(email) =>{
        // Espressione regolare per validare l'email
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    loginSubmit = (e) => {
        e.preventDefault();
    
        const { login } = this.state;
    
        if (login.username === "") {
            this.showErrorModal("Errore: Inserire un nome utente!");
            return;
        }
        if (login.password === "") {
            this.showErrorModal("Errore: Inserire una password!");
            return;
        }
    
        Auth.login(login.username, login.password)
        .then((response) => {
            if (response.data.serverAccess === true) {
                this.setState({
                    serverLists: response.data.serverList,
                    cardOpn: 4,
                    isLoggedIn: true
                }, () => {
                    console.log("Login avvenuto con successo!");
                    console.log("Stato attuale dopo il login:");
                    console.log(this.state);
                });
            } else {
                localStorage.setItem('user', JSON.stringify(response.data));
                this.props.authenticated(true);
                this.setState({
                    cardOpn: 4,
                    isLoggedIn: true
                }, () => {
                    console.log("Login avvenuto con successo!");
                    console.log("Stato attuale dopo il login:");
                    console.log(this.state);
                });
            }
            AlertC({
                variant: "success",
                children: "Autenticazione riuscita",
                timeOut: 4000
            });
        })
        .catch(err => {
            console.log(err.response.data.message);
            AlertC({
                variant: "danger",
                children: err.response.data.message,
                timeOut: 4000
            });
            // this.showErrorModal(err.response.data.message);
        });
    }
    

    registrationSubmit = (e) => {
        e.preventDefault();

        const { registration } = this.state;

        if (!registration.username.trim()) {
            AlertC({
                variant: "danger",
                children: "Errore: Inserire un nome utente!",
                timeOut: 4000
            });
            return;
        }
        if (!this.isValidEmail(registration.email)) {
            AlertC({
                variant: "danger",
                children: "Errore: Inserire un'email valida",
                timeOut: 4000
            });
            return;
        }

        if (registration.password.length < 6) {
            AlertC({
                variant: "danger",
                children: "Errore: Inserire una password di almeno 6 caratteri!",
                timeOut: 4000
            });
            return;
        }

        if (registration.password !== registration.repPassword) {
            AlertC({
                variant: "danger",
                children: "Errore: Le password non corrispondono!",
                timeOut: 4000
            });
            return;
        }

        Auth.register(registration.username, registration.email, registration.password, registration.repPassword)
            .then((response) => {
                // this.showSuccessModal("Registrazione avvenuta con successo!");
                AlertC({
                    variant: "success",
                    children: "Registrazione avvenuta con successo",
                    timeOut: 4000
                });
                this.setState((prevState) => ({
                    ...prevState,
                    registration: {
                        ...prevState.registration,
                        username: '',
                        password: '',
                        repPassword: '',
                        email: ''
                    }
                }));
                this.handleClose();

            })
            .catch(err => {
                console.log(err);
                // this.showErrorModal(err.message);
                AlertC({
                    variant: "danger",
                    children: err.message,
                    timeOut: 4000
                });
            });
    }

    resPassSubmit = (e) => {
        e.preventDefault();
    
        const { missingPass } = this.state;
    
        if (missingPass.email === "") {
            AlertC({
                variant: "danger",
                children: "Ricorda di inserire una email valida|ì!",
                timeOut: 4000
            });
            return;
        }
    
        Auth.restorePassword(missingPass.email)
        .then((response) => {
            AlertC({
                variant: "success",
                children: "Ripristino eseguito, riceverai una email con la nuova password",
                timeOut: 4000
            });
            this.setState((prevState) => ({
                ...prevState,
                missingPass: {
                    ...prevState.missingPass,
                    email: ''
                }
            }));
            this.handleClose();
        })
        .catch(err => {
            console.log(err.response.data.message);
            AlertC({
                variant: "danger",
                children: err.response.data.message,
                timeOut: 4000
            });
            // this.showErrorModal(err.response.data.message);
        });
    }

    handleChangeInputReg = (e) => {
        const { name, value } = e.currentTarget;
        this.setState((prevState) => ({
            ...prevState,
            registration: {
                ...prevState.registration,
                [name]: value
            }
        }));
    }

    handleChangeInputLogin = (e) => {
        const { name, value } = e.currentTarget;
        this.setState((prevState) => ({
            ...prevState,
            login: {
                ...prevState.login,
                [name]: value
            }
        }));
    }

    handleChangeInputMpass = (e) => {
        const { name, value } = e.currentTarget;
        this.setState((prevState) => ({
            ...prevState,
            missingPass: {
                ...prevState.missingPass,
                [name]: value
            }
        }));
    }

    setServer = (item) => {
        
        if(item.serv_status == 1 || item.serv_status == 2 && JSON.parse(localStorage.getItem('user')).user.rank == 3){
            Auth.firstLogin(item.serv_token)
            .then((response) => {   
                // Update the serverLists state with the data from the response
                
                window.location.href = process.env.REACT_APP_PROTOCOL + item.serv_token + ".ethernial.it/?pg=Index&act=StartSession&token="+response.data.res.content.token;
            })
            .catch(err => {
                // Handle errors if any
                console.log(err);
                // You may want to display an error message to the user
            });
        }else{
            AlertC({
                variant: "danger",
                children: "Il server è chiuso o non sei un Admin",
                timeOut: 4000
            });
        }
        

        // this.setState({
        //     serverSelected: index,
        //     cardOpn: 0
        // });
    }

    setCard = (e) => {
        this.setState({
            cardOpn: e
        });
    }

    showErrorModal = (message) => {
        const modal = this.state.modal;
        modal.push({
            message: message,
            variant: "error",
            show: true
        });
        this.setState({ modal });
    }

    showSuccessModal = (message) => {
        const modal = this.state.modal;
        modal.push({
            message: message,
            variant: "success",
            show: true
        });
        this.setState({ modal });
    }

    modalClose = (index) => {
        const modal = this.state.modal;
        modal[index].show = false;
        this.setState({ modal });
    }

    render() {
        const { t } = this.props;
        const { cardOpn, serverLists, registration, login, modal, isLoggedIn } = this.state;

        return (
            <Modal
                {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={[classes.modal, "registration"].join(" ")}
                backdrop="static"
                onHide={this.handleClose}
            >
                <Modal.Body className={classes.modalBody}>
                    <Button onClick={this.handleClose} className={["close", classes.buttonClose, "closeBtn", "position-absolute"].join(" ")}><span aria-hidden="true" className='text-transparent'>×</span><span className="sr-only">Close</span></Button>
                    <div className="modal-content__">
                        <div className="header text-center text-black font-extrabold uppercase text-2xl pt-5">
                            {
                                cardOpn === 0 ? "Registrati" : cardOpn === 1 ? "Accedi" : cardOpn === 3 ? "Recupera" : cardOpn === 4 ? "Server" : "" 
                            }
                        </div>
                        <div className="body">
                            {cardOpn === 0 && !isLoggedIn && (
                                <div className="registration_box">
                                    <Form onSubmit={this.registrationSubmit}>
                                        <Form.Group controlId="formBasicUsername" className="group-control mt-3">
                                            <Form.Label className='w-full block text-center'>Username</Form.Label>
                                            <Form.Control type="text" name="username" data-type="registration" onChange={this.handleChangeInputReg} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword" className="group-control mt-3">
                                            <Form.Label className='w-full block text-center'>Password</Form.Label>
                                            <Form.Control type="password" name="password" data-type="registration" onChange={this.handleChangeInputReg} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicRepeatPass" className="group-control mt-3">
                                            <Form.Label className='w-full block text-center'>Ripeti password</Form.Label>
                                            <Form.Control type="password" name="repPassword" data-type="registration" onChange={this.handleChangeInputReg} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail" className="group-control  mt-3">
                                            <Form.Label className='w-full block text-center'>Email</Form.Label>
                                            <Form.Control type="email" name="email" data-type="registration" onChange={this.handleChangeInputReg} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Button variant="success" type="submit" className='bg-green-700 h-10 rounded-md'>Registrati</Button>
                                        <a href="#" className='text-sm mb-10' onClick={() => this.setCard(1)}>Accedi</a>
                                    </Form>
                                </div>
                            )}
                            {cardOpn === 1 && !isLoggedIn && (
                                <div className="registration_box login">
                                    <Form onSubmit={this.loginSubmit}>
                                        <Form.Group controlId="formBasicUsernameLogin" className="group-control">
                                            <Form.Label className='w-full block text-center'>Username</Form.Label>
                                            <Form.Control type="text" name="username" data-type="login" onChange={this.handleChangeInputLogin} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPasswordLogin" className="group-control mt-3">
                                            <Form.Label className='w-full block text-center'>Password</Form.Label>
                                            <Form.Control type="password" name="password" data-type="login" onChange={this.handleChangeInputLogin} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Button className='bg-green-700 h-10 rounded-md' variant="success" type="submit">Accedi</Button>

                                        <a href="#" className='text-sm' onClick={() => this.setCard(0)}>Non hai un account?</a>
                                        <a href="#" className='text-sm' onClick={() => this.setCard(3)}>Password dimenticata?</a>
                                    </Form>
                                </div>
                            )}
                            {cardOpn === 3 && (
                                <div className="registration_box login">
                                    <Form onSubmit={this.resPassSubmit}>
                                        <Form.Group controlId="formBasicREmail" className="group-control">
                                            <Form.Label className='w-full block text-center'>Email</Form.Label>
                                            <Form.Control type="email" name="email" data-type="missingPass" onChange={this.handleChangeInputMpass} className="input-form !h-10 -mt-1" />
                                        </Form.Group>
                                        <Button variant="success" type="submit" className='bg-green-700 h-10 rounded-md'>Recupera password</Button>
                                        <a href="#" className='text-sm' onClick={() => this.setCard(0)}>Non hai un account?</a>
                                        <a href="#" className='text-sm' onClick={() => this.setCard(1)}>Torna indietro</a>
                                    </Form>
                                </div>
                            )}
                            {cardOpn === 4 && (
                                <div className="serverList">
                                    {serverLists.map((server, index) => (
                                    <div class="cardSvr">
                                        <div key={index} className="serverListItem" onClick={() => this.setServer(server)}>
                                            <div className="server_info">
                                                <div className='bg-slate-900/60 absolute h-full w-full'>
                                                    <span>{server.serv_name}</span>
                                                    <div className='server_status_info'>Stato: {server.serv_status == 1 ? "Aperto" : server.serv_status == 2 ? "Aperto solo agli Admin" : "Chiuso"}</div>
                                                    <div className='server_status_info'>Utenti registrati: {server.users_reg == undefined ? 0 :server.users_reg  }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                    <div className="flex justify-center w-full relative">
                                        <div className='absolute top-5'>
                                            <Button onClick={()=> window.location.href = "/Dashboard"} variant="success" type="submit" className='bg-green-700 h-10 rounded-md w-[200px]'>Esplora ethernial</Button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className="footer"></div>
                    </div>
                </Modal.Body>
                {/* <div className="position-fixed bottom-0 end-0 p-3">
                <Alert variant={"warning"}>sdsdf</Alert>
                    {modal.map((element, index) => (
                        // <Noty key={index} hideToast={() => this.modalClose(index)} show={element.show} delay={5000} text={element.message} />
                        <Alert key={index} variant={"warning"}>{element.message}</Alert>
                    ))}
                    
                </div> */}
            </Modal>
        );
    }
}

export default ModalRegistration;

import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export const isAuthenticated = () => {
    // Verifica se il token di autenticazione esiste nel localStorage
    const token = localStorage.getItem("user");
    // Aggiungi qui la logica per verificare se il token è valido o scaduto
    return token !== null;
};

export const logout = () => {
    localStorage.removeItem('user');
    window.location.href = "/";
}

export const getUser = () =>{
    return  JSON.parse(localStorage.getItem("user"));
}


export const RequireAuth = ({ Component, restricted, page = true }, ...props) => {
    const isAuthenticatedUser = isAuthenticated();
    const location = useLocation();
    const navigate = useNavigate();
    
    if (restricted && isAuthenticatedUser) { 
        
        // Se l'utente è già autenticato e la pagina è restritta, reindirizzalo alla home
        if (location.pathname == "/") { 
            return <Navigate to="/Dashboard" state={{ from: location }} />;
        }
    } else { 
        
        // Se l'utente non è autenticato e la pagina è restritta, reindirizzalo alla pagina di autenticazione
        if (!isAuthenticatedUser && page) {
            if(location.pathname != "/"){
                return <Navigate to="/" state={{ from: location }} />;
            }else{
                return <Component navigate={navigate} {...props} />;
            }
        }
    }

    // Restituisce il componente richiesto se l'utente è autenticato o se la pagina non è restrittiva
    return isAuthenticatedUser ? <Component navigate={navigate} {...props} /> : null;
};

